/**
 * Text check
 * 文字列が#から始まるかどうか
 * @module isId
 * @param  {String} $target - This String Check
 * @return {bool} - $target first character is "#" or not
 */
// module.exports = isId = ($target = '') => {
export default function isId($target = '') {
  const check = $target.match(/^#/);
  return check !== null;
}
