import $ from 'jquery';
/* eslint-disable no-unused-vars */
// import Swiper, { EffectCoverflow } from 'swiper';
import Swiper from './modules/swiper-bundle';
/* eslint-enable no-unused-vars */
import ScrollReveal from 'scrollreveal';
import 'magnific-popup';

import toggleExpanded from './modules/toggleExpanded';

/*
 *  document ready
 */
$(document).ready(() => {
  /* eslint-disable no-unused-vars */
  const swiper = new Swiper('.staff-slider', {
    spped: 1000,
    autoplay: {
      delay: 4000,
      disableOnInteraction: false,
    },
    loop: true,
    effect: 'coverflow',
    grabCursor: true,
    centeredSlides: true,
    slidesPerView: 'auto',
    coverflowEffect: {
      rotate: -20,
      stretch: 1,
      depth: 1,
      modifier: 1,
      slideShadows: false,
    },
  });

  if (/MSIE \d|Trident.*rv:/.test(navigator.userAgent)) {
    // IE
  } else {
    $('.js-mgp-ajax').magnificPopup({
      type: 'ajax',
      alignTop: true,
      overflowY: 'scroll',
      showCloseBtn: false,
      closeOnBgClick: false,
      fixedBgPos: true,
      fixedContentPos: true,
      callbacks: {
        ajaxContentAdded: function () {
          var m = this;
          this.content.find('.js-mgp-close').on('click', function (e) {
            e.preventDefault();
            m.close();
          });
          this.content.find('[data-scroll]').on('click', function (e) {
            const $this = $(this);
            if ($this.attr('data-scroll') == '#body') {
              e.preventDefault();
              $('.mfp-wrap').animate(
                {
                  scrollTop: 0,
                },
                200
              );
            } else if ($this.attr('data-scroll') == '#staff') {
              e.preventDefault();
              const target = '#gnav';
              const $target = $(target);
              toggleExpanded.toggleExpanded($(`[data-toggle="${target}"]`));
              toggleExpanded.toggleExpanded($target);
              toggleExpanded.fixedBody(target, $target);
              m.close();
            }
          });
        },
      },
    });
  }
  /* eslint-enable no-unused-vars */
});

// ページプラグインの埋め込みコードを返す
function pagePluginCode(w) {
  // 書き換えたStep3のコードを「return」に記述
  return (
    '<div class="fb-page" data-href="https://www.facebook.com/sweethome.uekado/" data-tabs="timeline" data-width="' +
    w +
    '" data-height="800" data-small-header="false" data-adapt-container-width="true" data-hide-cover="false" data-show-facepile="false"><blockquote cite="https://www.facebook.com/sweethome.uekado/" class="fb-xfbml-parse-ignore"><a href="https://www.facebook.com/sweethome.uekado/">株式会社ウエカド</a></blockquote></div>'
  );
}

// ページプラグインを追加する要素
var facebookWrap = $('.facebook-wrapper');
var fbBeforeWidth = ''; // 前回変更したときの幅
var fbWidth = facebookWrap.width(); // 今回変更する幅
var fbTimer = false;
$(window).on('load resize', function () {
  if (fbTimer !== false) {
    clearTimeout(fbTimer);
  }
  fbTimer = setTimeout(function () {
    fbWidth = Math.floor(facebookWrap.width()); // 変更後の幅を取得し、小数点以下切り捨て
    // 前回の幅から変更があった場合のみ処理
    if (fbWidth != fbBeforeWidth) {
      facebookWrap.html(pagePluginCode(fbWidth)); // ページプラグインのコード変更
      window.FB.XFBML.parse(); // ページプラグインの再読み込み
      fbBeforeWidth = fbWidth; // 今回変更分を保存
    }
  }, 200);
});

/* eslint-disable no-undef */
window.sr = ScrollReveal({
  debug: false,
  viewFactorz: 0.5,
  distance: '20px',
  duration: 1000,
  reset: false,
  afterReveal: function (el) {
    $(el).addClass('sr-done');
  },
});
sr.reveal('.js-sr-bottom');
sr.reveal('.js-sr-left', { origin: 'left' });
sr.reveal('.js-sr-right', { origin: 'right' });
// sr.reveal('.js-sr-top', { origin: 'top' });

sr.reveal('.js-sr-dur', { interval: 150 });
// sr.reveal('.js-sr-dur-left', { interval: 150, origin: 'left' });
// sr.reveal('.js-sr-dur-right', { interval: 150, origin: 'right' });

// sr.reveal('.js-sr', {
//   delay: 0,
//   distance: '0px',
//   duration: 600,
//   easing: 'cubic-bezier(0.5, 0, 0, 1)',
//   interval: 0,
//   opacity: 1,
//   origin: 'bottom',
//   rotate: {
//     x: 0,
//     y: 0,
//     z: 0,
//   },
//   scale: 1,
//   cleanup: false,
//   container: document.documentElement,
//   desktop: true,
//   mobile: true,
//   reset: false,
//   useDelay: 'always',
//   viewFactor: 0.0,
//   viewOffset: {
//     top: 0,
//     right: 0,
//     bottom: 0,
//     left: 0,
//   },
// });
/* eslint-enable no-undef */
