import $ from 'jquery';

import isId from './isIdString';

const myToggleExpanded = {};
const navId = '#gnav';
const $navId = $(navId);

/**
 * Toggle aria-expanded
 */
myToggleExpanded.toggleExpanded = function ($object) {
  if ($object.attr('aria-expanded') === 'true') {
    $object.attr('aria-expanded', 'false');
  } else {
    $object.attr('aria-expanded', 'true');
  }
};

/**
 * body fixed when open menu
 */
myToggleExpanded.fixedBody = function ($id, $object, _scroll = true) {
  const $body = $('body');
  if ($id === navId) {
    if ($object.attr('aria-expanded') === 'true') {
      const pos = $(window).scrollTop();
      $body.css({
        position: 'fixed',
        top: -pos,
      });
      $body.attr('data-position', pos);
    } else {
      const pos = $body.attr('data-position');
      $body.css({
        position: '',
        top: -pos,
      });
      if (_scroll) {
        window.scrollTo(0, pos);
        $body.attr('data-position', 0);
      }
    }
  }
};

myToggleExpanded.click = function ($obj = $('[data-toggle]')) {
  if ($obj instanceof jQuery) {
    $obj.on('click', function (e) {
      e.preventDefault();

      const target = $(this).attr('data-toggle');

      if (isId(target)) {
        const $target = $(target);
        myToggleExpanded.toggleExpanded($(`[data-toggle="${target}"]`));
        myToggleExpanded.toggleExpanded($target);
        myToggleExpanded.fixedBody(target, $target);
      }
    });
  }
};

myToggleExpanded.openNav = function () {
  if ($navId.attr('aria-expanded') == 'false') {
    $(`[data-toggle="${navId}"]`).attr('aria-expanded', 'true');
    $navId.attr('aria-expanded', 'true');
    myToggleExpanded.fixedBody(navId, $navId);
  }
};

myToggleExpanded.closeNav = function () {
  if ($navId.attr('aria-expanded') == 'true') {
    $(`[data-toggle="${navId}"]`).attr('aria-expanded', 'false');
    $navId.attr('aria-expanded', 'false');
    myToggleExpanded.fixedBody(navId, $navId);
  }
};

export default myToggleExpanded;
